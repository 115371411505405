<template>
  <div class="schedule-calendar-wrap">
    <div v-if="scheduleLoading">
      <v-skeleton-loader type="chip@3" class="d-flex jc-sb"></v-skeleton-loader>
      <v-skeleton-loader type="table-row-divider@7"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-sheet>
        <v-toolbar flat>
          <div>
            <v-btn
              class="calendar-previous-month"
              color="#2a2e52"
              text
              @click="previousMonth"
            >
              <v-icon> mdi-chevron-left </v-icon>
              Предыдущий месяц
            </v-btn>
          </div>
          <v-toolbar-title v-if="$refs.refScheduleCalendar">
            {{ $refs.refScheduleCalendar.title }}
          </v-toolbar-title>
          <div>
            <v-btn
              class="calendar-next-month"
              color="#2a2e52"
              text
              @click="nextMonth"
            >
              Следующий месяц
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-sheet>
      <v-sheet>
        <v-calendar
          ref="refScheduleCalendar"
          v-model="scheduleCalendar"
          type="month"
          :weekdays="weekday"
          :events="schedule"
          :event-ripple="false"
          event-color="transparent"
          event-text-color="2a2e52"
          :show-month-on-first="false"
          @click:day="viewDay"
          @click:date="viewDay"
          @click:event="viewDay"
          @click:more="viewDay"
          hide-header
          locale="ru-RU"
          color="primary"
        ></v-calendar>
      </v-sheet>
    </div>
    <v-dialog
      v-model="viewDaySchedule"
      @click:outside="closeDialog"
      width="485px"
      scrollable
    >
      <v-card v-if="scheduleOfDay">
        <v-card-title class="justify-center">
          {{
            new Date(scheduleCalendar).toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })
          }}
          <v-btn icon @click="closeDialog" class="modal-close-btn">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-row no-gutters v-if="scheduleOfDay.length === 0">
          <span class="text-h5 no-schedule"
            >Нет назначенных сотрудников на этот день</span
          >
        </v-row>
        <v-row no-gutters v-else-if="scheduleOfDay.length > 0">
          <v-col
            cols="12"
            class="staff-of-day"
            v-for="staff in scheduleOfDay"
            :key="staff.id"
          >
            <v-avatar>
              <v-img
                v-if="staff.details.user.user_avatar"
                :src="staff.details.user.user_avatar"
                lazy-src="@/assets/images/avatar.svg"
              ></v-img>
              <v-img v-else src="@/assets/images/avatar-white.svg"></v-img>
            </v-avatar>
            <div class="staff-info">
              <div class="staff-name">
                {{ staff.details.user.first_name }}
                {{ staff.details.user.last_name }}
              </div>
              <div class="staff-department-schedule">
                <div class="staff-department">{{ staff.details.position }}</div>
                <v-icon>mdi-circle-small</v-icon>
                <div class="staff-schedule">
                  {{ staff.start.toTimeString().substring(0, 5) }} -
                  {{ staff.end.toTimeString().substring(0, 5) }}
                </div>
              </div>
              <div class="staff-actual-schedule">
                <div class="staff-actual-start">Начало: --:--</div>
                <div class="staff-actual-end">Конец: --:--</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getStaffWorkScheduleApi } from "@/api/schedule";
import { staffApi } from "@/api/staff";
export default {
  data: () => ({
    scheduleCalendar: "",
    weekday: [1, 2, 3, 4, 5, 6, 0],
    schedule: [],
    staffList: [],
    scheduleLoading: true,
    viewDaySchedule: false,
  }),
  computed: {
    scheduleOfDay() {
      if (this.scheduleCalendar)
        return this.schedule.filter(
          (event) =>
            new Date(event.start).toISOString().substring(0, 10) ===
            this.scheduleCalendar.substring(0, 10)
        );
    },
  },
  created() {
    this.init();
  },
  updated() {
    // Called to show month title in v-calendar
    this.$refs.refScheduleCalendar.move(0);
  },
  methods: {
    init() {
      this.getStaffWorkSchedule();
    },
    async getStaffWorkSchedule() {
      try {
        const schedule_response = await getStaffWorkScheduleApi();
        let workSchedules = schedule_response.data;
        const staff_response = await staffApi();
        this.staffList = staff_response.data;
        this.schedule = workSchedules.map((schedule) => {
          let staffOfSchedule = this.staffList.find(
            (staff) => staff.user.id === schedule.staff
          );
          return {
            id: schedule.id,
            name: `${staffOfSchedule.user.first_name}
              ${staffOfSchedule.user.last_name.substring(0, 1)}.
              ${new Date(schedule.start_time)
                .toLocaleTimeString("ru-RU")
                .substring(0, 5)}-
              ${new Date(schedule.end_time)
                .toLocaleTimeString("ru-RU")
                .substring(0, 5)}`,
            start: new Date(schedule.start_time),
            end: new Date(schedule.end_time),
            details: staffOfSchedule,
          };
        });
      } catch (err) {
        throw err;
      } finally {
        this.scheduleLoading = false;
      }
    },
    closeDialog() {
      this.viewDaySchedule = false;
    },
    // Vuetify v-calendar functions
    previousMonth() {
      this.$refs.refScheduleCalendar.prev();
    },
    nextMonth() {
      this.$refs.refScheduleCalendar.next();
    },
    viewDay({ date }) {
      this.scheduleCalendar = date;
      this.viewDaySchedule = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  padding: 18px 28px;
  justify-content: center;
}
.col-12 {
  flex: auto;
}
.no-schedule {
  padding: 15px;
  text-align: center;
  color: #7f8297;
}
.staff-of-day {
  margin: 6px 24px;
  padding: 20px 0 !important;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f4f5ff;
}
.v-avatar {
  margin-left: 20px;
}
.staff-info {
  margin-left: 24px;
}
.staff-name {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.staff-department-schedule {
  margin-bottom: 10px;
  height: 19px;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #7f8297;
}
.staff-actual-schedule {
  display: flex;
}
.staff-actual-start {
  margin-right: 10px;
}
.staff-actual-start,
.staff-actual-end {
  padding: 5px 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #5b6cff;
}
</style>